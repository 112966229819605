import React, { useContext, useState } from "react";
import { Card, Row, Col, Divider } from "antd";
import { FocusContext } from "../../Context/FocusContext";

const InvoiceCard = () => {
  const { discountRef, invoiceData, taxVal, totalFinalAmount } =
    useContext(FocusContext);
  const [dis, setDis] = useState(0);
  const [otherAdj, setOtherAdj] = useState(0);
  const billAmt = totalFinalAmount - dis - otherAdj;

  const CGST = (billAmt * 6) / 100;
  const SGST = (billAmt * 6) / 100;

  const totalTaxCal = CGST + SGST;

  const roundOff = Math.round(billAmt + CGST + SGST);
  console.log(roundOff);

  const enterHandler = (e) => {
    if (e.key === "Escape") {
      let value = window?.confirm("Do you want to print the invoice");
      if (value) {
        alert(`Your Total amount is : ${roundOff} RS`);
      }
    }
  };

  return (
    <Card title="Invoice Information & Payment">
      <Row gutter={[12, 12]} justify="space-between" align="middle">
        <Col span={10} className="invoice_item">
          <div>Gross</div>
          <div>Cash Dis.</div>
          <div>Other Adj.</div>
          <div>Bill Amt.</div>
          <div>CGST(6%)</div>
          <div>SGST(6%)</div>
          <div>Total Tax(12%)</div>
          <div>Discount</div>
          <div>Round Off</div>
          <div>NET</div>
          <div>Paid</div>
          <div>Due</div>
        </Col>
        <Col span={8} className="invoice_Data">
          {/* Placeholder values - Replace with actual values */}
          <div>{totalFinalAmount}</div>
          <div>
            {" "}
            <input
              type="number"
              ref={discountRef}
              value={dis}
              onChange={(e) => setDis(e.target.value)}
            />
          </div>
          <div>
            {" "}
            <input
              type="number"
              value={otherAdj}
              onChange={(e) => setOtherAdj(e.target.value)}
              onKeyDown={(e) => enterHandler(e)}
            />
          </div>
          <div>{billAmt} Rs</div>
          <div>{CGST} Rs</div>
          <div>{SGST} Rs</div>
          <div>{totalTaxCal} Rs</div>
          <div>{dis} Rs</div>
          <div>{roundOff} Rs</div>
          <div>{roundOff} Rs</div>
          <div>{roundOff} Rs</div>
          <div>00.00 Rs</div>
        </Col>
      </Row>
    </Card>
  );
};

export default InvoiceCard;
