import React, { useState, useContext, useRef, useEffect } from "react";
import { Table, Input, Select, AutoComplete } from "antd";
import { FocusContext } from "../../Context/FocusContext";

const { Option } = Select;

const TaxTable = ({ productSearchRef }) => {
  const [dataVal, setDataVal] = useState([]);
  const qtyRef = useRef(null);
  const { discountRef, setTaxVal, setTotalVal, updateTotalFinalAmount } =
    useContext(FocusContext);

  useEffect(() => {
    // Initialize the data with a default row
    setDataVal([
      {
        key: "0",
        product: "",
        batch: "",
        expiryDate: "",
        mrp: 0,
        qty: 0,
        discount: 0,
        tax: 0,
        salesTax: 0,
        amount: 0,
        taxAmount: 0,
        finalAmount: 0,
      },
    ]);
  }, []);

  const data = [
    {
      key: "1",
      product: "Paracetamol tablets 500mg",
      batch: "ABC1234",
      expiryDate: "2025-03-31",
      mrp: 10.0,
      qty: 0,
      discount: 0,
      tax: 5,
      salesTax: 0.5,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "2",
      product: "Multivitamin tablets",
      batch: "DEF5678",
      expiryDate: "2024-10-01",
      mrp: 50.0,
      qty: 0,
      discount: 0,
      tax: 12,
      salesTax: 6.0,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "3",
      product: "Cough syrup for adults",
      batch: "GHI9012",
      expiryDate: "2025-05-15",
      mrp: 150.0,
      qty: 0,
      discount: 0,
      tax: 18.0,
      salesTax: 27.0,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "4",
      product: "Antibacterial cream",
      batch: "JKL3456",
      expiryDate: "2024-07-31",
      mrp: 75.0,
      qty: 0,
      discount: 0,
      tax: 5,
      salesTax: 3.75,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "5",
      product: "Insulin injection 10ml",
      batch: "MNO7890",
      expiryDate: "2025-01-01",
      mrp: 300.0,
      qty: 0,
      discount: 0,
      tax: 5,
      salesTax: 0,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "6",
      product: "Allergy relief tablets",
      batch: "PQR1234",
      expiryDate: "2024-09-01",
      mrp: 25.0,
      qty: 0,
      discount: 0,
      tax: 12,
      salesTax: 3.0,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "7",
      product: "Pain relief gel",
      batch: "STU5678",
      expiryDate: "2025-08-31",
      mrp: 125.0,
      qty: 0,
      discount: 0,
      tax: 5,
      salesTax: 6.25,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "8",
      product: "Antibiotic capsules",
      batch: "VWX9012",
      expiryDate: "2024-12-31",
      mrp: 100.0,
      qty: 0,
      discount: 0,
      tax: 18.0,
      salesTax: 0.5,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "9",
      product: "Digestive enzymes tablets",
      batch: "YZA3456",
      expiryDate: "2025-02-28",
      mrp: 40.0,
      qty: 0,
      discount: 0,
      tax: 5,
      salesTax: 2.0,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
    {
      key: "10",
      product: "Vitamin C effervescent tablets",
      batch: "BCD7890",
      expiryDate: "2024-11-15",
      mrp: 35.0,
      qty: 0,
      discount: 0,
      tax: 12,
      salesTax: 4.2,
      amount: 0,
      taxAmount: 0,
      finalAmount: 0,
    },
  ];

  const handleQtyChange = (key, value) => {
    // Ensure that the quantity is never less than 1
    if (value < 1) {
      value = 1;
    }

    const newData = dataVal.map((item) => {
      if (item.key === key) {
        const amount = (item.mrp - item.discount) * value;
        const taxAmount = (amount * item.tax) / 100;
        const finalAmount = amount + taxAmount + item.salesTax;
        setTaxVal(taxAmount);
        setTotalVal(finalAmount);
        return { ...item, qty: value, amount, taxAmount, finalAmount };
      }
      return item;
    });

    setDataVal(newData);
  };

  useEffect(() => {
    const totalFinalAmount = dataVal.reduce(
      (total, item) => total + item.finalAmount,
      0
    );
    updateTotalFinalAmount(totalFinalAmount);
  }, [dataVal,updateTotalFinalAmount]);

  // search product focus
  // const handleProductSelectByKeyDown=(event)=>{
  //   if (event.key === "Enter" || event.key === "Tab") {
  //     event.preventDefault();
  //     productRef.current.focus();
  //   }
  // }

  // qyt focus
  const handleDateByKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      qtyRef.current.focus();
    }
    if (event.key === "Escape") {
      console.log("focus");
      discountRef.current.focus();
    }
  };

  const handleProductSelect = (value, option, key) => {
    const selectedProductData = option.data;

    if (selectedProductData) {
      const newData = dataVal.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            product: selectedProductData.product,
            batch: selectedProductData.batch,
            expiryDate: selectedProductData.expiryDate,
            mrp: selectedProductData.mrp,
            tax: selectedProductData.tax,
            salesTax: selectedProductData.salesTax,
            qty: item.qty || 0,
            discount: item.discount || 0,
            amount:
              (selectedProductData.mrp - (item.discount || 0)) *
              (item.qty || 0),
            taxAmount:
              ((selectedProductData.mrp - (item.discount || 0)) *
                (item.qty || 0) *
                selectedProductData.tax) /
              100,
            finalAmount:
              (selectedProductData.mrp - (item.discount || 0)) *
                (item.qty || 0) +
              ((selectedProductData.mrp - (item.discount || 0)) *
                (item.qty || 0) *
                selectedProductData.tax) /
                100 +
              selectedProductData.salesTax,
          };
        }
        return item;
      });

      setDataVal(newData);

      // Focus on the quantity input field after selecting the product
      setTimeout(() => {
        const qtyInput = document.getElementById(`qtyInput_${key}`);
        if (qtyInput) {
          qtyInput.focus();
        }
      }, 0);
    }
  };

  const handleEnterPress = (record) => {
    // Check if the record object is defined and if it has a product selected
    if (record && record.product !== "") {
      // Check if the record object is defined and if it has a qty property greater than or equal to 1
      if (record.qty !== undefined && record.qty >= 1) {
        // Create a new empty row
        setDataVal((prevData) => [
          ...prevData,
          {
            key: `${Date.now()}`,
            product: "",
            batch: "",
            expiryDate: "",
            mrp: 0,
            qty: 0,
            discount: 0,
            tax: 0,
            salesTax: 0,
            amount: 0,
            taxAmount: 0,
            finalAmount: 0,
          },
        ]);

        // Focus on the product section of the newly created row
        setTimeout(() => {
          productSearchRef.current.focus();
        }, 0);
      }
    }
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: "13%",
      render: (text, record) => (
        <AutoComplete
          style={{ width: "100%" }}
          dataSource={data.map((x) => x.product)}
          placeholder="Search for a product"
          onSelect={(value, option) =>
            handleProductSelect(value, option, record.key)
          }
          // filterOption={(inputValue, option) =>
          //   option.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          // }
          ref={productSearchRef}
        >
          {data.map((x) => (
            <Option key={x.key} value={x.product} data={x}>
              {x.product}
            </Option>
          ))}
        </AutoComplete>
      ),
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "branch",
      width: "8%",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: "8%",
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      width: "3%",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "7%",
      render: (text, record) => (
        <Input
          id={`qtyInput_${record.key}`}
          type="number"
          value={text}
          onChange={(e) =>
            handleQtyChange(record.key, parseInt(e.target.value, 10) || 0)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEnterPress(record);
            } else {
              handleDateByKeyDown(e);
            }
          }}
          ref={qtyRef}
        />
      ),
    },
    {
      title: "Dis",
      dataIndex: "discount",
      key: "discount",
      width: "4%",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      width: "4%",
    },
    {
      title: "Sales Tax",
      dataIndex: "salesTax",
      key: "salesTax",
      width: "4%",
    },
    {
      title: "Amt",
      dataIndex: "amount",
      key: "amount",
      width: "7%",
    },
    {
      title: "Tax Amt",
      dataIndex: "taxAmount",
      key: "taxAmount",
      width: "7%",
    },
    {
      title: "Final Amt",
      dataIndex: "finalAmount",
      key: "finalAmount",
      width: "7%",
    },
  ];

  return (
    <div className="table">
      <Table
        dataSource={dataVal}
        columns={columns}
        bordered
        pagination={false}
        scroll={{ x: "max-content" }}
        onRow={() => ({
          onKeyDown: (event) => {
            if (event.key === "Enter") {
              handleEnterPress();
            }
          },
        })}
      />
    </div>
  );
};

export default TaxTable;
