import { createContext, useRef, useState } from "react";

export const FocusContext = createContext();

const FocusProvider = ({ children }) => {
  const discountRef = useRef(null);
  const [invoiceData, setInvoiceData] = useState([]);
  const [taxVal, setTaxVal] = useState(0);
  const [totalVal, setTotalVal] = useState(0);
  const [totalFinalAmount, setTotalFinalAmount] = useState(0);
  console.log(invoiceData);

  const updateTotalFinalAmount = (amount) => {
    setTotalFinalAmount(amount);
  };

  const detailHandler = (item) => {
    setInvoiceData([...invoiceData, item]);
  };

  return (
    <FocusContext.Provider
      value={{
        discountRef,
        detailHandler,
        invoiceData,
        setTaxVal,
        setTotalVal,
        taxVal,
        totalVal,
        updateTotalFinalAmount,
        totalFinalAmount
      }}
    >
      {children}
    </FocusContext.Provider>
  );
};

export default FocusProvider;
